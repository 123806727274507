.stockContainer{
    /*grid-column: 1/2;*/
    display: grid;
    overflow: hidden;
    width: 100%; 
    height: 100%;
    /*max-width: 364px; */
    }
    
    .stockSlider{
      grid-area:1/1/1/2;
      border-radius: 20px;
      transition: transform 1s;
    
    }
    
    .dots__dot{
      border: none;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
    transition: all 0.5s;
    }
    
    .dots__dot--active{
      background-color:#1937b9
    }
    
    .stockSlider__container h2{
      grid-area:1/1/1/3;
      margin-bottom: 20px;
      padding: 0 20px;
    
    }
    
    .stockSlider__photos{
      display: grid;
      grid-template-rows: 1fr;
      overflow:hidden;
      overflow-x:scroll;
      max-width: 390px;
      height: 200px;
    
    }
    
    .stockSlider__photoslider{
      margin: 20px auto;
      padding: 0 20px;
    
    }
    
    .stockSlider__photos__img{
      grid-area:1/1/1/3;
     width: 100%;
    max-height: 201px;
    }
    
    .lazystockslider{
      height:200px;
      filter: blur(20px)
    }
    
    
    .stockSlider__textContainer{
      grid-area:3/1/3/3;
      display: grid;
      padding: 10px;
      margin-left: auto;
      margin-right: auto;
      text-align: left;
    }
    
    .stockSlider__name{
      grid-column: 1/3;
      text-align: center;
    
    }
    
    .stockSlider__name h3{
      font-weight: bold;
    
    }
    
    .stockSlider__textDescription{
      max-width: 100%;
    margin: 0 auto;
    }
    
    a.stockSlider__phone{
      text-align: left;
      padding-left: 60px;
    }
    
    .stockSLider__price a{
      font-size: 17px;
      text-align: right;
      /*font-family: open-sans-light;*/
      font-weight: bold;
    
    
    }
    
    .stockSlider__price{
      grid-column: 2/3;
      text-align: right;
    }
    
    .stockSlider__container p{
      text-align: left;
    
    
    }
    
    .stockSlider__bottomButtons{
      grid-area:4/1/4/3;
      display: grid;
      grid-template-columns: repeat(2,auto);
    max-width: 100%;
    margin: 20px auto;
    margin-bottom:0;
    padding: 10px;
    }
    
    .stockSlider__phone{
      color: #1937b9;
    cursor: pointer;
    font-family: open-sans-light;
    font-size: 16px;
    }
    
    .stockSlider__details a{
      margin-top: 20px;
      color: #1937b9;
    cursor: pointer;
    font-family: open-sans-light;
    font-size: 16px;
    }
    
    .stockSLider__button{
    border: none;
    background: rgba(255, 255, 255, 0.7);
    color: #2f2f2f;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    cursor: pointer;
    font-size:20px;
    
    }
    
    .stockSlider__button__container{
      margin-top: 30px;

    }