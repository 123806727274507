.stockContainer {
  width: 100%;
  height: 100%;
  display: grid;
  overflow: hidden;
}

.stockSlider {
  border-radius: 20px;
  grid-area: 1 / 1 / 1 / 2;
  transition: transform 1s;
}

.dots__dot {
  cursor: pointer;
  border: none;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  margin-right: 5px;
  transition: all .5s;
}

.dots__dot--active {
  background-color: #1937b9;
}

.stockSlider__container h2 {
  grid-area: 1 / 1 / 1 / 3;
  margin-bottom: 20px;
  padding: 0 20px;
}

.stockSlider__photos {
  grid-template-rows: 1fr;
  max-width: 390px;
  height: 200px;
  display: grid;
  overflow: scroll hidden;
}

.stockSlider__photoslider {
  margin: 20px auto;
  padding: 0 20px;
}

.stockSlider__photos__img {
  grid-area: 1 / 1 / 1 / 3;
  width: 100%;
  max-height: 201px;
}

.lazystockslider {
  filter: blur(20px);
  height: 200px;
}

.stockSlider__textContainer {
  text-align: left;
  grid-area: 3 / 1 / 3 / 3;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  display: grid;
}

.stockSlider__name {
  text-align: center;
  grid-column: 1 / 3;
}

.stockSlider__name h3 {
  font-weight: bold;
}

.stockSlider__textDescription {
  max-width: 100%;
  margin: 0 auto;
}

a.stockSlider__phone {
  text-align: left;
  padding-left: 60px;
}

.stockSLider__price a {
  text-align: right;
  font-size: 17px;
  font-weight: bold;
}

.stockSlider__price {
  text-align: right;
  grid-column: 2 / 3;
}

.stockSlider__container p {
  text-align: left;
}

.stockSlider__bottomButtons {
  grid-area: 4 / 1 / 4 / 3;
  grid-template-columns: repeat(2, auto);
  max-width: 100%;
  margin: 20px auto 0;
  padding: 10px;
  display: grid;
}

.stockSlider__phone {
  color: #1937b9;
  cursor: pointer;
  font-family: open-sans-light;
  font-size: 16px;
}

.stockSlider__details a {
  color: #1937b9;
  cursor: pointer;
  margin-top: 20px;
  font-family: open-sans-light;
  font-size: 16px;
}

.stockSLider__button {
  color: #2f2f2f;
  cursor: pointer;
  background: #ffffffb3;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
}

.stockSlider__button__container {
  margin-top: 30px;
}

/*# sourceMappingURL=index.ca64dd66.css.map */
